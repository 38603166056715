import { PagePropsOverride } from '@/models';
import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from './footer';
import Navbar from './navbar';

const Layout = (props: PagePropsOverride) => {
  return (
    <>
      <Navbar {...props} />
      {props.children}
      <Footer {...props} />
      <Helmet>
        <script
          src='https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/js/bootstrap.bundle.min.js'
          async={true}
        ></script>
      </Helmet>
    </>
  );
};

export default Layout;
