import { PagePropsOverride } from '@/models';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const Navbar = (props: PagePropsOverride) => {
  return (
    <nav className='navbar navbar-expand-md py-3'>
      <div className='container-xl'>
        <a className='navbar-brand d-flex align-items-center' href='#'>
          <StaticImage
            src='../../assets/img/logo.png'
            alt=''
            loading='eager'
            layout='constrained'
          />
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#main-navbar-collapse'
          aria-controls='main-navbar-collapse'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='1em'
            height='1em'
            fill='currentColor'
            viewBox='0 0 16 16'
          >
            <path d='M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z'></path>
          </svg>
        </button>
        <div className='collapse navbar-collapse' id='main-navbar-collapse'>
          <ul className='navbar-nav mx-auto'>
            <li className='nav-item'>
              <Link to='/' className='nav-link' activeClassName='active'>
                <span
                  data-bs-target='#main-navbar-collapse'
                  data-bs-toggle='collapse'
                >
                  Home
                </span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/build' className='nav-link' activeClassName='active'>
                <span
                  data-bs-target='#main-navbar-collapse'
                  data-bs-toggle='collapse'
                >
                  Why Build With Us?
                </span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/gallery' className='nav-link' activeClassName='active'>
                <span
                  data-bs-target='#main-navbar-collapse'
                  data-bs-toggle='collapse'
                >
                  Gallery
                </span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/contact' className='nav-link' activeClassName='active'>
                <span
                  data-bs-target='#main-navbar-collapse'
                  data-bs-toggle='collapse'
                >
                  Contact Us
                </span>
              </Link>
            </li>
          </ul>
        </div>
        <div className='d-none d-lg-inline-flex phone'>
          <span className='bs-icon-sm d-flex justify-content-center align-items-center me-2 bs-icon'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='1em'
              height='1em'
              fill='currentColor'
              viewBox='0 0 16 16'
              className='bi bi-telephone'
            >
              <path d='M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z'></path>
            </svg>
          </span>
          <span>{props.data.site.siteMetadata.phoneNumber}</span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
