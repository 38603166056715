import Layout from '@/components/core/layout';
import { ISite } from '@/models';
import { ShouldUpdateScrollArgs, WrapPageElementBrowserArgs } from 'gatsby';
import React from 'react';

export const wrapPageElement = ({
  element,
  props,
}: WrapPageElementBrowserArgs<ISite>) => {
  return <Layout {...props}>{element}</Layout>;
};

export const shouldUpdateScroll = ({}: ShouldUpdateScrollArgs) => {
  window.scrollTo({ left: 0, top: 0 });

  return false;
};
