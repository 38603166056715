import { ISite, PagePropsOverride } from '@/models';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';

const Footer = ({ data }: PagePropsOverride) => {
  return (
    <footer>
      <div className='container py-4 py-lg-5'>
        <div className='row justify-content-center footer-border'>
          <div className='col-sm-4 col-md-3 d-flex flex-column col-md-6'>
            <h4 className='font-family-serif'>
              {data.site.siteMetadata.title}
            </h4>
            <ul className='list-unstyled'>
              <li>Located in {data.site.siteMetadata.address}</li>
              <li>
                <a href={`tel:${data.site.siteMetadata.phoneNumber}`}>
                  {data.site.siteMetadata.phoneNumber}
                </a>
              </li>
              <li>
                <a
                  href={`mailto:${data.site.siteMetadata.email}`}
                  className='unstyled'
                >
                  {data.site.siteMetadata.email}
                </a>
              </li>
            </ul>
          </div>
          <div className='col-sm-4 col-md-3 d-flex flex-column'>
            <h5>Site Links</h5>
            <ul className='list-unstyled'>
              <li>
                <Link to='/'>Home</Link>
              </li>
              <li>
                <Link to='/build'>Why Build With Us?</Link>
              </li>
              <li>
                <Link to='/gallery'>Gallery</Link>
              </li>
              <li>
                <Link to='/contact'>Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className='col-lg-3 text-center d-flex flex-column align-items-center align-items-lg-start order-lg-last social'>
            <div className='fw-bold d-flex align-items-center mb-2'>
              <h5>Follow Us</h5>
            </div>
            <a
              href='https://www.facebook.com/Clarkcustomhomesllc'
              target='_blank'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='1em'
                height='1em'
                fill='currentColor'
                viewBox='0 0 16 16'
                className='bi bi-facebook'
                style={{ fontSize: '32px' }}
              >
                <path d='M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z'></path>
              </svg>
            </a>
          </div>
        </div>
        <div className='row footer-border'>
          <div className='col-md-6'>
            <div className='d-flex pt-3'>
              <p className='mb-0'>
                Copyright © {new Date().getFullYear()}{' '}
                {data.site.siteMetadata.title}
              </p>
            </div>
          </div>
          <div className='col-md-6 text-start text-md-end'>
            <span>Site by&nbsp;</span>
            <a href='https://bakerstreet.industries'>Bakerstreet Industries</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
